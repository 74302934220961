/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "./App.css";
// import bullet from "./Img/bullet.svg";
import close from "./Img/close.svg";
// import dots from "./Img/dots.svg";
import holdingPhone from "./Img/holding-phone.jpg";
import illustration from "./Img/illustration.svg";
import menu from "./Img/menu.svg";
import video from "./Img/video.svg";

function App() {
  // const mobileBtn = document.getElementById("mobile-cta");
  // const nav = document.querySelector("nav");
  // const mobileBtnExit = document.getElementById("mobile-exit");

  // mobileBtn.addEventListener("click", () => {
  //   nav.classList.add("menu-btn");
  // });

  // mobileBtnExit.addEventListener("click", () => {
  //   nav.classList.remove("menu-btn");
  // });
  return (
    <div className="App">
      {/* <!--NAVIGATION BLOG START--> */}
      <div class="nav-bar">
        <div class="container">
          <a class="logo-nav" href="">
            Alium <span>Software</span>
          </a>
          <img
            id="mobile-cta"
            class="mobile-menu"
            src={menu}
            alt="navigation"
          />
          <nav>
            <img
              id="mobile-exit"
              class="mobile-menu-exit"
              src={close}
              alt="close navigation"
            />
            <ul class="primary-nav">
              <li class="current">
                <a href="#">Home</a>
              </li>
            </ul>

            <ul class="second-nav">
              <li>
                <a href="#">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {/* <!--NAVIGATION BLOG END--> */}

      {/* <!--SECTION HERO BLOG START--> */}
      <section class="hero">
        <div class="container">
          <div class="left-col">
            <p class="sub-head">Alium Software</p>
            <h1>cloud services you can trust</h1>

            <div class="hero-cta">
              <a href="#" class="primery-cta">
                Book Free Consultation
              </a>
            </div>
          </div>

          <img src={illustration} alt="Illustration" class="hero-img" />
        </div>
      </section>
      {/* <!--SECTION HERO BLOG END--> */}

      {/* <!--SECTION FEATURES BLOG START--> */}
      <section class="features-section">
        <div class="container">
          <ul>
            <li>custom application development</li>
            <li>consultancy services</li>
            <li>azure cloud development</li>
            <li>medical and banking standards</li>
            <li>devops and process design</li>
            <li>kubernetes and gitops</li>
          </ul>
        </div>
        <img src={holdingPhone} alt="phone" />
      </section>
      {/* <!--SECTION FEATURES BLOG END--> */}

      {/* <!--SECTION CONTACT  BLOG START--> */}
      {/* <section class="contact-section">
        <div class="container">
          <div class="contact-left">
            <h2>Contact</h2>
            <form action="#" method="post">
              <label for="name">Name</label>
              <input type="text" id="name" name="name" />
              <label for="message">Message</label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
              ></textarea>

              <input type="submit" value="send message" class="send-message" />
            </form>
          </div>

        </div>
      </section> */}

      {/* <!--JAVASCRIPT START-->
     <script>
            
     </script> */}
    </div>
  );
}

export default App;
